import React from "react"
import animateScrollTo from 'animated-scroll-to'
import * as rocketStyles from "./rocket.module.scss"

class Rocket extends React.Component {
    constructor(props) {
        super(props)
        this.state = {show: false, hidden: true};
    }
    
    componentDidMount() {
        window.addEventListener('scroll', this.listenToScroll)
    }
      
    componentWillUnmount() {
        window.removeEventListener('scroll', this.listenToScroll)
    }

    listenToScroll = () => {
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        if(winScroll > 40 ) {
            this.setState({show: true, hidden: false})
        } else {
            this.setState({show: false})
        }
    }

    scrollToTop() {
        animateScrollTo(0, {maxDuration: 2000});
    }

    render() {
        const { show, hidden } = this.state
        return(
            <div onClick={this.scrollToTop} 
                className={`${rocketStyles.rocket} ${show ? rocketStyles.showRocket: rocketStyles.hideRocket} 
                                ${hidden ? "": rocketStyles.visible}`}>
                <div className={rocketStyles.arrow}></div>
            </div>
        )
    }
}

export default Rocket